import styled from "styled-components"

import { useStore } from "state/store"
import { APART_PROPS } from "dataset/finder"
import FloorPlateElement from "./Plans/FloorPlateElement"
import UpArrow from "assets/images/icons/floorup.svg"
import DownArrow from "assets/images/icons/floordown.svg"
import Context from "./Context"

function FloorPlate({ isFav, isResi = undefined, apartment, floor }) {
  const apartments = useStore(s => s.apartments)

  const setActiveApartment = useStore(s => s.setActiveApartment)
  const setActiveElementID = useStore(state => state.setActiveElementID)
  const setFullCardOpen = useStore(s => s.setFullCardOpen)
  const activeApartment = useStore(state => state.activeApartment)
  function handleClick(e, id) {
    const selectedApartment = apartments.filter(apt => apt[APART_PROPS.unit] === id)
    const unit = Number(selectedApartment[0]["Unit Number"].split(".")[1])
    setActiveElementID(unit)
    // console.log(selectedApartment[0])

    setActiveApartment(selectedApartment[0])

    if (activeApartment?.[APART_PROPS.unit].toString() === id) {
      setFullCardOpen(true)
    }
  }

  const setActiveFloor = useStore(s => s.setActiveFloor)

  let floorsAvailable = 22

  const handleClickFloorScroll = (e, id) => {
    if (!isFav) {
      if (id === "UP") {
        if (parseInt(floor) === floorsAvailable) {
          setActiveFloor("1")
        } else setActiveFloor(((parseInt(floor) + 1) % (floorsAvailable + 1)).toString())
      } else if (floor - 1 === 0) {
        setActiveFloor(floorsAvailable.toString())
      } else setActiveFloor((parseInt(floor) - 1).toString())
    }
  }

  let dataID

  if (parseInt(floor) >= 1 && parseInt(floor) <= 6) {
    dataID = "oneToSix"
  } else if (parseInt(floor) >= 7 && parseInt(floor) <= 8) {
    dataID = "sevenToEight"
  } else if (parseInt(floor) >= 9 && parseInt(floor) <= 19) {
    dataID = "nineToNineteen"
  } else if (parseInt(floor) >= 20 && parseInt(floor) <= 21) {
    dataID = "twentyToTwentyOne"
  } else if (parseInt(floor) === 22) {
    dataID = "twentyToTwentyTwo"
  }

  // dataID = "twentyToTwentyTwo"
  return (
    <Wrapper
      id={`${floor}floor`}
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 600 600"
      enableBackground="new 0 0 600 600"
      xmlSpace="preserve">
      <Context />
      <g transform="scale(0.75) translate(120,205)">
        {!isFav && (
          <g>
            <NextFloor
              href={UpArrow}
              onClick={e => handleClickFloorScroll(e, "UP")}
              transform="scale(0.24)"
              x={1160}
              y={10}
            />
            <NextFloor
              href={DownArrow}
              onClick={e => handleClickFloorScroll(e, "DOWN")}
              transform="scale(0.24)"
              x={1160}
              y={2330}
            />
          </g>
        )}
        <FloorPlateElement
          isFav={isFav}
          isResi={isResi}
          apartment={apartment}
          planDataID={dataID}
          floor={floor}
          handleClick={handleClick}
        />
      </g>
    </Wrapper>
  )
}

export default FloorPlate
const NextFloor = styled.image``

const Wrapper = styled.svg`
  width: 100%;

  /* height: 100%; */
  user-select: none;

    @media (max-width: 660px) {
    transform: scale(1);
    width: 100%;
    margin: 0;
    position: relative;
    z-index: 2; 
  }

  & text {
    fill: #edd8c8;
    font-size: 30px;
    font-family: Grande;
    cursor: pointer;
  }
`
