export const navPositions = [
  {
    name: "",
    title: "",
    path: "M15.105 80H3L1 80.3L8.04167 100H10L17 80.3L15.125 80Z",
    pos: { x: 8, y: 90 },
  },
  {
    name: "home",
    title: "Nine Elms Park",
    path: "M15.125 210H3L1 213.3L7.04167 223H11.3333L17 213.3L15.125 210Z",
    pos: { x: 8, y: 222 },
  },
  {
    name: "residences",
    title: "Residences",
    path: "M15.125 342H3L1 345.3L7.04167 355H11.3333L17 345.3L15.125 342Z",
    pos: { x: 8, y: 354 },
  },
  {
    name: "penthouses",
    title: "Penthouses",
    path: "M15.125 475H3L1 478.3L7.04167 488H11.3333L17 478.3L15.125 475Z",
    pos: { x: 8, y: 488 },
  },
  {
    name: "showhome",
    title: "Tour",
    path: "M15.125 605H3L1 608.3L7.04167 618H11.3333L17 608.3L15.125 605Z",
    pos: { x: 8, y: 618 },
  },
  {
    name: "location",
    title: "Location",
    path: "M15.125 735H3L1 738.3L7.04167 748H11.3333L17 738.3L15.125 735Z",
    pos: { x: 8, y: 748 },
  },
  {
    name: "gallery",
    title: "Gallery",
    path: "M15.125 872H3L1 875.3L7.04167 885H11.3333L17 875.3L15.125 872Z",
    pos: { x: 8, y: 885 },
  },
  {
    name: "about",
    title: "About",
    path: "M15.125 1009H3L1 1012.3L7.04167 1022H11.3333L17 1012.3L15.125 1009Z",
    pos: { x: 8, y: 1022 },
  },
]
