import { APART_PROPS } from "dataset/finder"
import { getMinPrice, getMaxPrice } from "./getPrice"

const csvToJson = async csvPromise => {
  try {
    const response = await csvPromise
    const csv = await response.text()

    const { header, apartmentsLines } = processCsv(csv)
    const allApartments = []
    const availableApartments = []

    for (let line of apartmentsLines) {
      const apartment = {}
      line.forEach((field, index) => {
        apartment[header[index]] = field
      })

      allApartments.push(apartment)

      if (apartment[APART_PROPS.status] == "1") {
        availableApartments.push(apartment)
      }
    }

    return { allApartments, availableApartments }
  } catch (error) {
    console.error("Error processing CSV:", error)
    return { allApartments: [], availableApartments: [] }
  }
}

const processCsv = csv => {
  const data = csv.split("\r\n").map(line => line.split(","))
  const header = data[0]
  const apartmentsLines = data.slice(4)
  return { header, apartmentsLines }
}

const setPrices = (apartment, setMinPrice, setMaxPrice, setPriceRange) => {
  const nonPenthouseApts = apartment.filter(
    apt => !["2202", "2204", "2206"].includes(apt[APART_PROPS.plot]),
  )
  const minPriceApartment = getMinPrice(nonPenthouseApts)
  const maxPriceApartment = getMaxPrice(nonPenthouseApts)

  const minPrice = Number(minPriceApartment[APART_PROPS.price]) - 10
  const maxPrice = Number(maxPriceApartment[APART_PROPS.price]) + 10

  setMinPrice(minPrice)
  setMaxPrice(maxPrice)
  setPriceRange([minPrice, maxPrice])
}

export default { csvToJson, setPrices }
