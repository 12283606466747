import apartmentsFn from "utils/apartmentsFn"
import offlineApartments from "../dataset/apartmentsOffline2.json"

const APARTMENTS_URL = process.env.REACT_APP_APARTMENTS_URL

export async function getApartments() {
  try {
    const response = await fetch(APARTMENTS_URL)

    if (!response.ok) {
      throw new Error("Failed to fetch apartments")
    }

    return apartmentsFn.csvToJson(response)
  } catch (err) {
    console.log("Falling back to offline data due to error: ", err)

    // Return offline data in same format as online data
    return {
      allApartments: offlineApartments,
      availableApartments: offlineApartments.filter(apt => apt.Availability === "1"),
    }
  }
}
