import { forwardRef } from "react"
import styled from "styled-components/macro"
import { AnimatePresence, motion } from "framer-motion"

import FloorPlate from "components/FloorPlate/FloorPlate"

import { useStore } from "state/store"
import { APART_PROPS } from "dataset/finder"

const ResidencesFloorplate = forwardRef(function ResidencesFloorplate({ isFav }, ref) {
  const filterVisible = useStore(state => state.filterVisible)
  const activeFloor = useStore(s => s.activeFloor)
  const apartment = useStore(s => s.activeApartment)
  const floor = isFav ? apartment[APART_PROPS.floor] : activeFloor

  return (
    <AnimatePresence>
      {!filterVisible && floor && (
        <Drawer
          ref={ref}
          initial="initial"
          animate="animate"
          exit="exit"
          variants={{
            initial: {
              opacity: 0,
            },
            animate: {
              y: 0,
              opacity: 1,
            },
            exit: {
              opacity: 0,
            },
          }}
          transition={{ duration: 0.8 }}>
          <FloorPlateWrapper style={isFav ? { pointerEvents: "none" } : {}}>
            <FloorPlate
              isFav={isFav}
              isResi={true}
              apartment={apartment}
              floor={floor}
            />
            <Gradient />
          </FloorPlateWrapper>
        </Drawer>
      )}
    </AnimatePresence>
  )
})

export default ResidencesFloorplate

const Drawer = styled(motion.div)`
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  width: 35%;
  max-width: 600px;
  height: 60%;
`

const FloorPlateWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 1%;

      @media (max-width: 660px) {
        margin-top: 7rem;
          height: auto;
  }

  & svg {
    max-height: 100%;
    opacity: 1;
    filter: none;
  }
`

const Gradient = styled.div`
  position: absolute;
  pointer-events: none;
  top: 0;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  background: radial-gradient(circle at 50% 58%, transparent 20%, #000000ba 65%, black 100%);
`
