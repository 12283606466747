import { useEffect, useRef } from "react"
import styled from "styled-components/macro"
import { useNavigate, useLocation } from "react-router-dom"
import { useTransition, useSpring } from "@react-spring/core"
import { animated } from "@react-spring/web"
import { navPositions } from "./positions"
import { useState } from "react"
import { useStore } from "state/store"
import useLongPress from "utils/hooks/useLongPress"

export default function Nav() {
  const navigate = useNavigate()
  const location = useLocation()
  const visibleText = useStore(s => s.visibleText)

  const [activeNav, setActiveNav] = useState(location.pathname)

  const handleNavigate = pathName => {
    if (pathName !== location.pathname) {
      setActiveNav(pathName)
      setTimeout(() => {
        navigate(pathName)
      }, 900)
    }
  }
  const fullScreenViews = useStore(s => s.fullScreenViews)

  const { opacity } = useSpring({
    opacity: fullScreenViews ? 0 : 1,
  })
  return location.pathname === "/share" ||
    location.pathname === "/" ||
    location.pathname === `/${btoa("encodeEmailGallery")}` ? null : (
    <NavBar style={{ opacity: opacity }}>
      <svg
        style={{ overflow: "visible", pointerEvents: "none" }}
        viewBox="0 0 400 1080"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path d="M9 1080L9 1022" stroke="#B58762" />
        <path d="M9 1009V885" stroke="#B58762" />
        <path d="M9 872L9.00001 748" stroke="#B58762" />
        <path d="M9 735V618" stroke="#B58762" />
        <path d="M9 604.5V488" stroke="#B58762" />
        <path d="M9 475L9.00001 355" stroke="#B58762" />
        <path d="M9 342V223.5" stroke="#B58762" />
        <path d="M9 210V100" stroke="#B58762" />
        <path d="M9 80V0" stroke="#B58762" />

        {navPositions.map((data, i) => (
          <NavMenuElement
            key={`navElm${i}`}
            activeNav={activeNav}
            handleNavigate={handleNavigate}
            setActiveNav={setActiveNav}
            visibleText={visibleText}
            data={data}
            index={i}
          />
        ))}
      </svg>
      <AppVersion>v{process.env.REACT_APP_VERSION}</AppVersion>
    </NavBar>
  )
}

function NavMenuElement({ data, activeNav, handleNavigate, visibleText, index }) {
  const pathName = `/${data.name}`
  const location = useLocation()
  const inTransition = useRef(false)
  const setVisibleText = useStore(s => s.setVisibleText)
  const setRefreshPage = useStore(state => state.setRefreshPage)

  const longPress = useLongPress(() => {
    handleNavigate(pathName)
    setRefreshPage(prev => !prev)
  }, 1000)

  const [active, setActive] = useState(false)
  const [current, setCurrent] = useState(false)

  useEffect(() => {
    if (activeNav === pathName) {
      setCurrent(true)
      setActive(true)
    } else setCurrent(false)

    setTimeout(() => {
      setActive(false)
    }, 3800)
  }, [pathName, activeNav])

  const transition = useTransition((active && current) || visibleText, {
    onStart: () => (inTransition.current = true),
    onRest: () => (inTransition.current = false),
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  })

  return (
    <>
      <Marker
        style={{ cursor: inTransition.current ? "auto" : "pointer" }}
        d={data.path}
        stroke="#B58762"
        active={location.pathname === pathName}
      />

      <InvisibleButton
        {...(index === 0 && { ...longPress })}
        onClick={e => {
          if (index === 0) {
            setVisibleText(true)
            setTimeout(() => {
              setVisibleText(false)
            }, 3800)
            return null
          }
          handleNavigate(pathName)
          !inTransition.current && handleNavigate(pathName)
        }}
        cx={data.pos.x}
        cy={data.pos.y - 7}
        r="45"
        fill="transparent"
      />
      <>
        {transition(
          (styles, item) =>
            item && (
              <NavText style={styles} x="25" y={data.pos.y}>
                {data.title}
              </NavText>
            ),
        )}
      </>
    </>
  )
}

const InvisibleButton = styled.circle`
  pointer-events: auto;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);

  cursor: pointer;
`

const NavBar = styled(animated.div)`
  z-index: 1000;
  position: fixed;
  top: 0;
  left: 2em;
  z-index: 999;
  height: 100%;
  width: 0;
  overflow: visible;
  justify-content: center;
  align-items: center;

  svg {
    height: 100%;
  }
`

const Marker = styled.path`
  pointer-events: none;
  transition: all 0.3s ease-out;
  fill: ${props => (props.active ? "#a27a5a" : "#ffffff00")};
  cursor: pointer;
`

const NavText = styled(animated.text)`
  fill: #b58762;
  letter-spacing: 0.1em;
  font-size: 0.6em;
  font-weight: bold;
  transform: translateY(-1px);
`
const AppVersion = styled.div`
  position: absolute;
  bottom: 0em;
  left: -2.6em;
  color: #666666;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
  font-size: 0.7em;
`
